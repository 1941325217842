import type { RouteRecordRaw } from 'vue-router';

import SettingsRouteName from './SettingsRouteName';

const PublicApiSettings = async () => import('./public-api-settings/PublicApiSettings.vue');
const ClickApiSettings = async () => import('./click-api-settings/ClickApiSettings.vue');
const ColumnsSettings = async () => import('./columns-settings/ColumnsSettings.vue');
const ImportExportSettings = async () => import('./import-export-settings/ImportExportSettings.vue');
const LandingPageProtectSettings = async () => import('./landing-page-protect-settings/LandingPageProtectSettings.vue');
const IntegrationSettings = async () => import('./integration-settings/IntegrationSettings.vue');
const MainStatsSettings = async () => import('./main-stats-settings/MainStatsSettings.vue');
const PostbackProcessingSettings = async () => import('./postback-processing-settings/PostbackProcessingSettings.vue');
const TrackingLinksSettings = async () => import('./tracking-links-settings/TrackingLinksSettings.vue');
const UrlCustomizationSettings = async () => import('./url-customization-settings/UrlCustomizationSettings.vue');
const UserSettings = async () => import('./user-settings/UserSettings.vue');
const AutoClearingsSettings = async () => import('./auto-clearings-settings/AutoClearingsSettings.vue');
const FeedbackSettings = async () => import('./feedback-settings/FeedbackSettings.vue');
const UiSettings = async () => import('./ui-settings/UiSettings.vue');

const SettingsPage = async () => import('../../pages/settings/SettingsPage.vue');

export default function getSettingsRoutes(): RouteRecordRaw[] {
  return [
    {
      children: [
        {
          component: TrackingLinksSettings,
          meta: {
            title: 'Tracking links - Settings',
          },
          name: SettingsRouteName.TRACKING_LINKS_SETTINGS,
          path: 'tracking-links',
        },
        {
          component: AutoClearingsSettings,
          meta: {
            title: 'Auto clearings clicks',
          },
          name: SettingsRouteName.AUTO_CLEARING,
          path: 'auto-clearing',
        },
        {
          component: UserSettings,
          meta: {
            title: 'User - Settings',
          },
          name: SettingsRouteName.USER_SETTINGS,
          path: 'user',
        },
        {
          component: UiSettings,
          meta: {
            title: 'User Interface - Settings',
          },
          name: SettingsRouteName.UI_SETTINGS,
          path: 'ui',
        },
        {
          component: MainStatsSettings,
          meta: {
            title: 'Stats - Settings',
          },
          name: SettingsRouteName.STATS_SETTINGS,
          path: 'stats',
        },
        {
          component: ColumnsSettings,
          meta: {
            title: 'Columns - Settings',
          },
          name: SettingsRouteName.COLUMNS_SETTINGS,
          path: 'columns',
        },
        {
          component: PublicApiSettings,
          meta: {
            title: 'Public API - Settings',
          },
          name: SettingsRouteName.PUBLIC_API_SETTINGS,
          path: 'public-api',
        },
        {
          component: ClickApiSettings,
          meta: {
            title: 'Click API - Settings',
          },
          name: SettingsRouteName.CLICK_API_SETTINGS,
          path: 'click-api',
        },
        {
          component: LandingPageProtectSettings,
          meta: {
            title: 'LP protect - Settings',
          },
          name: SettingsRouteName.LANDING_PAGE_PROTECT_SETTINGS,
          path: 'lp-protect',
        },
        {
          component: IntegrationSettings,
          meta: {
            title: 'Integration - Settings',
          },
          name: SettingsRouteName.INTEGRATION,
          path: 'integration',
        },
        {
          component: PostbackProcessingSettings,
          meta: {
            title: 'Postback processing - Settings',
          },
          name: SettingsRouteName.POSTBACK_PROCESSING_SETTINGS,
          path: 'postback-processing',
        },
        {
          component: ImportExportSettings,
          meta: {
            title: 'Import/Export - Settings',
          },
          name: SettingsRouteName.IMPORT_EXPORT_SETTINGS,
          path: 'import-export',
        },
        {
          component: UrlCustomizationSettings,
          meta: {
            title: 'URL customization - Settings',
          },
          name: SettingsRouteName.URL_CUSTOMIZATION_SETTINGS,
          path: 'url-customization',
        },
        {
          component: FeedbackSettings,
          meta: {
            title: 'Feedback - Settings',
          },
          name: SettingsRouteName.FEEDBACK_SETTINGS,
          path: 'feedback',
        },
      ],
      component: SettingsPage,
      meta: {
        title: 'Settings',
        useIn: ['menu'],
      },
      name: SettingsRouteName.SETTINGS,
      path: '/settings',
      redirect: {
        name: SettingsRouteName.TRACKING_LINKS_SETTINGS,
      },
    },
  ];
}
